import React from "react";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const PublicRoutes = ({
  isAuth,
  redirectPath = isMobile ? "/sportsbook/Cricket" : "/sports",
  children,
}) => {
  if (isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
export default PublicRoutes;
