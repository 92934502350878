import PlayBtn from "../../assets/images/play-btn.png";

export const indianCardGames = [
  {
    gameUrl: "/casino/ezg-namaste-roulette",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/namasteroulette.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-one-day-teen-patti",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/ONEDAYTEENPATTI.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-bet-on-teen-patti",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/betonteenpatti.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-32-cards",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/32cards.jpg",
    playImg: PlayBtn,
  },

  {
    gameUrl: "/casino/ezg-ultimate-andar-bahar",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/andarbaharultimate.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-andar-bahar",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/anadarbahar.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-lucky-7",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/lucky7.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-casino-marina-andar-bahar",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/casinomarinanadarbahar.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezg-cricket-war",
    homeUrl: "/indiancardgames",
    img: "./images/indiancardgames/cricketwarrr.jpg",
    playImg: PlayBtn,
  },
];
