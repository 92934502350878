import PlayBtn from "../../assets/images/play-btn.png";

export const slotsData = [
  // {
  //   gameUrl: "/casino/ezgevo-gonzos-treasure-hunt",
  //   homeUrl: "/slots",
  //   img: "./images/slots/newCasino/SlotsImg2.jpg",
  //   playImg: PlayBtn,
  // },
  {
    gameUrl: "/casino/ezgne-asgardian-stones",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/asgardianstones.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-secrets-of-atlantis",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/secrets-of-atlantis.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-grand-wheel",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/grand-wheel.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-777-strike",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/777strike.jpg",
    playImg: PlayBtn,
  },

  {
    gameUrl: "/casino/ezgrt-cash-ultimate",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/cash_ultimate.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-cash-volt",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/cash_volt.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-dazzle-me-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/dazzle_me_megaways.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-divine-fortune-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/divine_fortune_megaways.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-dynamite-riches",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/dynamite_riches.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-fortune-house",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/fortunehouse.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-fruit-shop-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Fruit_Shop_Megaways.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-golden-cryptex",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Golden_Cryptex.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-golden-tsar",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Golden_Tsar.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-gorilla-kingdom",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Gorilla_Kingdom.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-mystery-reels-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mystery_Reels_Megaways.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-rage-of-the-seas",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Rage_Of_The_Seas.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-reel-king-mega",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Reel_King_Mega.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-turn-your-fortune",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Turn_Your_Fortune.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-twin-spin-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Twin_Spin_Megaways.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgne-willys-hot-chillies",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Willys_Hot_Chillies.jpg",
    playImg: PlayBtn,
  },
  {
    gameUrl: "/casino/ezgrt-chinese-treasures",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Chinese-Treasures.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-dragons-luck",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dragon's-Luck.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-dragons-fire",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dragon's-Fire.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-dragons-luck-deluxe",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dragon's-Luck-Deluxe.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-dragons-luck-power-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dragon's-Luck-Power-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-epic-journey",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Epic-Journey.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-god-of-wealth",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/God-Of-Wealth.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-lucky-fortune-cat",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Lucky-Fortune-Cat.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-magic-gate",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Magic-Gate.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-golden-temple",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Golden-Temple.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-phoenix-fire-power-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Phoenix-Fire-Power-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-golden-offer",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Golden-Offer.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-ras-legend",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/RA's-Legend.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-gonzos-quest-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Gonzo's-Quest-Megaways.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-rainbow-jackpots",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Rainbow-Jackpots.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mega-pyramid",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mega-Pyramid.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-jimihendrixonlineslot",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jimi_Hendrix.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-jumanji",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jumanji.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-motorheadvideoslot",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Motorhead_Video_Slot.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-starburst",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Starburst.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-war-of-gods",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/War-Of-Gods.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-aloha-christmas",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Aloha-Christmas.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-aloha-cluster-pays",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Aloha-Cluster-Pays.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-ancients-blessing",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Ancients-Blessing.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-aurum-codex",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Aurum-Codex.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-gunsnrosesvideoslots",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Guns_N_Roses.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/qt-betty-boris-and-boo",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Betty-Boris-and-Boo.jpg",
    playImg: PlayBtn,
    casino: "Qtech",
    provider: "WorldCasino",
  },
  {
    gameUrl: "/casino/ezgne-wild-wild-west-the-great-train-heist",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wild-Wild-West-The-Great-Train-Heist.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-steam-tower",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Steam-Tower.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-zeus-lightning-power-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Zeus-Lightning-Power-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-wings-of-ra",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wings-of-Ra.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-trillionaire",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Trillionaire.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-treasure-mine",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Treasure-Mine.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-thors-vengeance",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Thor's-Vengeance.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-regal-streak",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Regal-Streak.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-regal-beasts",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Regal-Beasts.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-reel-keeper",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Reel-Keeper.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-rainbow-jackpots-power-lines",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Rainbow-Jackpots-Power-Lines.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-piggy-riches-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Piggy-Riches-Megaways.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-jingle-bells",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jingle-Bells.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-jack-in-a-pot",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jack-in-a-Pot.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-five-star",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Five-Star.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-bounty-raid",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Bounty-Raid.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mystery-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mystery-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-diamond-blitz",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Diamond-Blitz.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-dragons-fire-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dragon's-Fire-Megaways.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-rome-the-golden-age",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Rome-The-Golden-Age.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-reel-rush",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Reel-Rush.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-blood-suckers",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Blood-Suckers.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-blood-suckers-2",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Blood-Suckers-2.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-pyramid-quest-for-immortality",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Pyramid-Quest-for-Immortality.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-butterfly-staxx",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Butterfly-Staxx.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-butterfly-staxx-2",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Butterfly-Staxx-2.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-piggy-riches",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Piggy-Riches.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-dark-king-forbidden-riches",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dark-King-Forbidden-Riches.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-jingle-bells-power-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jingle-Bells-Power-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-jingle-spin",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jingle-Spin.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-dazzle-me",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dazzle-Me.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-jack-hammer",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jack-Hammer.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-jack-hammer-2-fishy-business",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jack-Hammer-2-Fishy-Business.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-dead-or-alive",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dead-or-Alive.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-dead-or-alive-2",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dead-or-Alive-2.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-dead-or-alive-2-feature-buy",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dead-or-Alive-2-Feature-Buy.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-jack-and-the-beanstalk",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jack-and-the-Beanstalk.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-gordonramsayhellskitchen",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Gordon_Ramsay_Hells_Kitchen.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-elements-the-awakening",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Elements-The-Awakening.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-gonzosquest",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Gonzos_Quest.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-finn-and-the-swirly-spin",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Finn-and-the-Swirly-Spin.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-fruit-shop",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Fruit-Shop.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-dazzle-me",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dazzle-Me.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-double-stacks",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Double-Stacks.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-fairytale-legends-red-riding-hood",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Fairytale-Legends-Red-Riding-Hood.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-flowers",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Flowers.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-fruit-shop-christmas-edition",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Fruit-Shop-Christmas-Edition.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-hotline",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Hotline.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-hotline-2",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Hotline-2.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezg-theinvisibleman",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/The_Invisible_Man.jpg",
    playImg: PlayBtn,
    casino: "Ezugi",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-irish-pot-luck",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Irish-Pot-Luck.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-fruit-spin",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Fruit-Spin.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-jungle-spirit-call-of-the-wild",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jungle-Spirit-Call-of-the-Wild.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-koi-princess",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Koi-Princess.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-drive-multiplier-mayhem",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Drive-Multiplier-Mayhem.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-rage-of-the-seas",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Rage-of-the-Seas.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-riches-of-midgard-land-and-expand",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Riches-of-Midgard-Land-and-Expand.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-scruffy-duck",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Scruffy-Duck.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-secret-of-the-stones",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Secret-of-the-Stones.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-secrets-of-christmas",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Secrets-of-Christmas.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-serengeti-kings",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Serengeti-Kings.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-space-wars",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Space-Wars.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-victorious",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Victorious.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-warlords-crystal-of-power",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Warlords-Crystal-of-Power.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-wild-water",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wild-Water.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgne-wild-worlds",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wild-Worlds.jpg",
    playImg: PlayBtn,
    casino: "Net Ent",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-4-squad",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/4-Squad.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-5-families",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/5-Families.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-agent-royale",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Agent-Royale.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-arcade-bomb",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Arcade-Bomb.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-atlantis",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Atlantis.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-aztec-spins",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Aztec-Spins.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-cinderellas-ball",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Cinderella`s-Ball.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-cirque-de-la-fortune",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Cirque-de-la-Fortune.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-clash-of-the-beasts",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Clash-of-the-Beasts.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-crazy-genie",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Crazy-Genie.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-crystal-mirror",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Crystal-Mirror.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-da-vincis-mystery",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Da-Vinci`s-Mystery.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-devils-number",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Devil`s-Number.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-dice-dice-dice",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Dice-Dice-Dice.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-five-stars-power-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Five-Stars-Power-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-gems-gone-wild-power-reels",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Gems-Gone-Wild-Power-Reels.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-gems-gone-wild",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Gems-Gone-Wild.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-golden-leprechaun-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Golden-Leprechaun-Megaways.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-hoard-of-poseidon",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Hoard-of-Poseidon.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-jewel-scarabs",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Jewel-Scarabs.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-legend-of-athena",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Legend-of-Athena.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-lucky-halloween",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Lucky-Halloween.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-lucky-little-devil",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Lucky-Little-Devil.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-lucky-valentine",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Lucky-Valentine.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-lucky-wizard",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Lucky-Wizard.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-masquerade",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Masquerade.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mayan-gods",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mayan-Gods.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mega-dragon",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mega-Dragon.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mega-jade",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mega-Jade.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mega-rise",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mega-Rise.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-multiplier-riches",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Multiplier-Riches.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-mystery-reels-megaways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Mystery_Reels_Megaways.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-ninja-ways",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Ninja-Ways.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-path-of-destiny",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Path-of-Destiny.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-persian-fortune",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Persian-Fortune.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-reel-heist",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Reel-Heist.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-robin-hoods-wild-forest",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Robin-Hood`s-Wild-Forest.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-snow-wild-and-the-7-features",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Snow-Wild-And-The-7-Features.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-sylvan-spirits",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Sylvan-Spirits.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-the-greatest-train-robbery",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/The-Greatest-Train-Robbery.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-the-wild-hatter",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/The-Wild-Hatter.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-thors-lightning",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Thor`s-Lightning.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-tiki-fruits",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Tiki-Fruits.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-totem-lightning",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Totem-Lightning.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-vault-cracker",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Vault-Cracker.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-vault-of-anubis",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Vault-of-Anubis.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-well-of-wishes",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Well-of-Wishes.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-wild-cats-multiline",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wild-Cats-Multiline.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-wild-oclock",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wild-O`Clock.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-wild-wild-chest",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Wild-Wild-Chest.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-win-escalator",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Win-Escalator.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
  {
    gameUrl: "/casino/ezgrt-yucatans-mystery",
    homeUrl: "/slots",
    img: "./images/slots/newCasino/Yucatan`s-Mystery.jpg",
    playImg: PlayBtn,
    casino: "Red Tiger",
    provider: "Ezugi",
  },
];
